import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Layout } from '../components/Layout';
import COLORS from '../utils/colors';
import { PostSnapshot } from '../components/PostSnapshot';
import { Tags } from '../components/Tags';
import { Pagination } from '../components/Pagination';

const BlogList = ({ data, pageContext }) => {
  return (
    <Layout>
      <h1>The Blog</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PostListWrapper>
          {data.allMdx.edges.map(
            ({ node: { id, excerpt, frontmatter, fields } }) => (
              <PostSnapshot
                slug={fields.slug}
                title={frontmatter.title}
                date={frontmatter.date}
                id={id}
              >
                <p style={{ maxWidth: '90%' }}>{excerpt}</p>
              </PostSnapshot>
            )
          )}
        </PostListWrapper>
        <Tags group={data.tags.group} />
      </div>
      <Pagination context={pageContext} />
    </Layout>
  );
};

export default BlogList;

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 125)
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD-MMM-YY")
          }
        }
      }
    }

    tags: allMdx(
      filter: { frontmatter: { published: { eq: true } } }
      sort: { fields: [frontmatter___tags], order: DESC }
      limit: 8
    ) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;

// Styles
const PostListWrapper = styled.main`
  margin-right: 1em;
  color: ${COLORS.black};
`;
