import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'gatsby';
import styled from 'styled-components';
import COLORS from '../utils/colors';

export const Tags = ({ group }) => (
  <div>
    <TagTitleWrapper>
      <TagTitle>Tags</TagTitle>
    </TagTitleWrapper>
    <TagBox>
      {group.map((tag) => (
        <TagLink to={`/tags/${kebabCase(tag.fieldValue)}/`}>
          {tag.fieldValue}
        </TagLink>
      ))}
    </TagBox>
  </div>
);

// Styles
const TagTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TagTitle = styled.h3`
  margin-top: 0em;
  margin-bottom: 0.3em;
`;

const TagLink = styled(Link)`
  text-decoration: none;
  background: ${COLORS.green};
  color: ${COLORS.offwhite};
  white-space: nowrap;
  margin: 0.2em;
  padding: 0 0.2em;
`;

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;
  justify-content: center;
  margin-bottom: 2em;
`;
