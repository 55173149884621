import { Link } from 'gatsby';
import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { InlineLink } from '../elements/InlineLink';
import styled from 'styled-components';
import COLORS from '../utils/colors';

export const Pagination = ({ context }) => {
  const { currentPage, numPages } = context;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? '/blog/' : '/blog/' + (currentPage - 1).toString();
  const nextPage = '/blog/' + (currentPage + 1).toString();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {!isFirst && (
        <InlineLink
          to={prevPage}
          style={{ display: 'flex', alignItems: 'center' }}
          rel='prev'
        >
          <IoIosArrowBack size='1.2em' />
        </InlineLink>
      )}
      <NumberWrapper>
        {Array.from({ length: numPages }, (_, i) => (
          <PaginateNumber
            key={`pagination-number${i + 1}`}
            to={`/blog/${i === 0 ? '' : i + 1}`}
            activeClassName='active'
          >
            {i + 1}
          </PaginateNumber>
        ))}
      </NumberWrapper>
      {!isLast && (
        <InlineLink
          to={nextPage}
          style={{ display: 'flex', alignItems: 'center' }}
          rel='next'
        >
          <IoIosArrowForward size='1.2em' />
        </InlineLink>
      )}
    </div>
  );
};

const NumberWrapper = styled.div`
  margin: 0 0.6em;
`;

const PaginateNumber = styled(Link)`
  color: ${COLORS.blue};
  float: left;
  padding: 0 0.5em;
  text-decoration: none;
  font-size: 1.2em;

  &:hover {
    background-color: ${COLORS.offwhite};
  }

  &.active {
    background-color: ${COLORS.green};
    color: ${COLORS.white};
    cursor: default;
  }
`;
