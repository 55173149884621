import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FadedText } from '../elements/FadedText';

export const PostSnapshot = ({ id, slug, title, date, children }) => (
  <PostWrapper key={id}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <PostLink to={slug}><h4>{title}</h4></PostLink>
      <FadedText>{date}</FadedText>
    </div>
    {children}
  </PostWrapper>
);

const PostLink = styled(Link)`
  color: inherit;
  font-size: 1.25em;
  text-decoration: none;
`;

const PostWrapper = styled.div`
  padding: 1em 0;
`;
